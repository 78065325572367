import React, { useEffect, useState } from "react";
import { getList } from "../../api/productApi";
import useCustomLogin from "../../hooks/useCustomLogin";
import { useModal } from "../common/ModalContext";
import { getMyOrder } from "../../api/myApi";
import ProductRowComponent from "./ProductRowComponent";
import { toast } from "react-toastify";

const initState = [];

const ProductListComponent = () => {
  const { isLogin, loginState, moveToLogin } = useCustomLogin();

  const [serverData, setServerData] = useState(initState);

  const [myOrder, setMyOrder] = useState([]);

  console.log("^^loginState : ", loginState.memberId);
  const memberIdx = loginState.memberIdx;
  const memberId = loginState.memberId;

  const nation = null; //"kr";
  const pridx = null; //1;

  useEffect(() => {
    getList({ nation, pridx })
      .then((response) => {
        setServerData(response.data);
      })
      .catch((err) => {
        toast.error(err.message, { theme: "dark" });
      });

    // 회원 주문 정보
    getMyOrder({ memberId: memberId })
      .then((response) => {
        //console.log("^^ getMyOrder-response : ", response);

        // API 응답 데이터가 배열인지 확인
        if (Array.isArray(response)) {
          setMyOrder(response); // 상태에 데이터 할당
        } else {
          toast.error(response, { theme: "dark" });
        }
      })
      .catch((err) => {
        toast.error(err.message, { theme: "dark" });
      });
  }, [nation, pridx, memberIdx, memberId]);

  return (
    <>
      <div className="shop">
        <div className="top">
          {/* <div className="coin-box">
            <em></em>
            <p>2,390</p>
          </div> */}
        </div>

        <div className="shop-list">
          <div className="list-inner">
            {serverData.length > 0 ? (
              serverData.map((product) => {
                // console.log(
                //   "^^myOrder2 :",
                //   myOrder,
                //   "^^len : ",
                //   myOrder.length
                // );

                // 하나 이상의 요소가 제공된 테스트 함수를 통과하는지 확인하는 함수:some
                const isOrder = myOrder.some((order) =>
                  order.orderDetails.some(
                    (detail) => detail.product.productIdx === pridx
                  )
                );

                const productData = { product, isOrder };

                // console.log(
                //   "^^ProductListComponent-productData : ",
                //   productData
                // );

                return (
                  <ProductRowComponent
                    key={product.productIdx}
                    data={productData}
                  />
                );
              })
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductListComponent;
