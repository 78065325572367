import React, { useEffect, useState } from "react";
import useCustomLogin from "../../hooks/useCustomLogin";
import { getCookie } from "../../util/cookieUtil";
import RankRowComponent from "./RankRowComponent";
import { getRank } from "../../api/gameApi";
import { toast } from "react-toastify";
import { dd } from "framer-motion/client";
import { numberWithCommas } from "../../util/numberUtil";
import { isEmpty } from "../../util/stringUtil";
import { API_SERVER_HOST } from "../../api/apiConfig";

const RankComponent = ({ t }) => {
  //console.log("^^RankComponent : ", t);

  const [serverData, setServerData] = useState([]);

  const { isLogin, loginState } = useCustomLogin();
  // console.log("^^record-isLogin", isLogin, "#", loginState);
  // console.log("^^record-cookie : ", getCookie("member"));

  const memberId = loginState.memberId;

  const host = API_SERVER_HOST;

  useEffect(() => {
    setServerData([]); // 초기화
    getRank({ t: t, memberId: memberId })
      .then((response) => {
        if (Array.isArray(response)) {
          //setServerData(response);
          setServerData(response);
        } else {
          console.error("Expected an array but received:", response);
        }
      })
      .catch((err) => {
        toast.error(err, { theme: "dark" });
      });
  }, [t]);

  if (serverData) {
    //console.log("^^serverData: ", serverData);
  }

  return (
    <div className="tab-cont">
      <div className="list">
        {serverData.length > 0 ? (
          serverData
            .filter((rank) => rank.rankType === "A")
            .map((rank) => {
              return (
                <RankRowComponent key={t + "-" + rank.myGameIdx} data={rank} />
              );
            })
        ) : (
          <></>
        )}
      </div>

      {serverData.length > 0 ? (
        serverData
          .filter((rank) => rank.rankType === "B" && rank.rank > 10)
          .map((rank) => {
            const photo = isEmpty(rank.photo)
              ? "/img/00-icon-profile.png"
              : host + "/api/file/member/" + rank.photo;
            return (
              <>
                <div className="brief">
                  <em></em>
                  <em></em>
                  <em></em>
                </div>
                <div className="my-rk">
                  <div className="rk-box">
                    <div className="left">
                      <div className="spot">
                        <p className="rank">{rank.rank}</p>
                        <em className="rk-sam"></em>
                      </div>
                    </div>

                    <div className="center">
                      <div className="photo">
                        <img src={photo} alt="" />
                      </div>
                      <div className="info">
                        <div className="nick">
                          <p>{rank.nickname}</p>
                        </div>

                        <div className="lv-progress">
                          <div className="lv">
                            <em className="icon"></em>
                            <div className="txt">
                              {rank.periodName} {rank.gameCount}
                            </div>
                          </div>

                          <div className="progress">
                            <div className="percent w98"></div>
                            <div className="txt">{rank.progress}%</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="right">
                      <div className="point">
                        <div className="icon"></div>
                        <p className="txt">
                          {numberWithCommas(rank.totalPoint)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })
      ) : (
        <></>
      )}
    </div>
  );
};

export default RankComponent;
