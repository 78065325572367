import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postSaveGame } from "../../api/gameApi";

/**
 * 게임화면 상단 대시보드
 */
export const postGameSaveAsync = createAsyncThunk(
  "postGameSaveAsync",
  async (param, { rejectWithValue }) => {
    try {
      const response = await postSaveGame(param);
      return response;
    } catch (error) {
      // 에러 메시지를 명확히 전달
      return rejectWithValue(error);
    }
  }
);

// 데이터 타입 매핑 (true -> 1, false -> 0)
const initState = {
  memberId: "",
  myGameIdx: 0,
  heart: 50, // 생명
  hodu: 0, // 호두(캐쉬)
  pridx: 0, // 시대키
  kdidx: 0, // 왕국키
  rank: 0, // 랭킹
  totalPoint: 0, // 누적 점수
  progress: 0, // 진행률
  gameCount: 0, // 진행 개수(인덱스값)(게임)
  gameType: "", // 게임 구분(KING,BONUS)
  gcIdxs: [], // 게임(왕)키
  optIdxs: [], // 선택지(마지막)키
  gcPoint: [], // 게임(왕)별 점수
  gcAtStart: [], // 게임시작시간(timestamp)
  gcAtEnd: [], // 게임종료시간
  answer: [], // 제출정답
  isCorrect: [], // 정답유무
  status: "idle", // idle | loading | succeeded | failed
  error: null,
};

const gameSlice = createSlice({
  name: "gameSlice",
  initialState: initState,
  reducers: {
    resetGame: (state, action) => {
      return {
        ...initState, // 기존 초기 상태값
        ...action.payload, // 특정 값만 덮어씌움
      };
    },
    updateGameInfo: (state, action) => {
      const {
        memberId,
        myGameIdx,
        heart,
        hodu,
        pridx,
        kdidx,
        rank,
        totalPoint,
        progress,
        gameCount,
        gameType,
        gcIdxs,
        optIdxs,
        gcPoint,
        gcAtStart,
        gcAtEnd,
        answer,
        isCorrect,
      } = action.payload;

      if (typeof memberId !== "undefined") {
        //console.log("^^setGameControl memberId : ", memberId);
        state.memberId = memberId;
      }
      if (typeof myGameIdx !== "undefined") {
        //console.log("^^setGameControl myGameIdx : ", myGameIdx);
        state.myGameIdx = myGameIdx;
      }
      if (typeof heart !== "undefined") {
        //console.log("^^setGameControl heart : ", heart);
        state.heart = heart;
      }
      if (typeof hodu !== "undefined") {
        //console.log("^^setGameControl hodu : ", hodu);
        state.hodu = hodu;
      }
      if (typeof pridx !== "undefined") {
        //console.log("^^setGameControl pridx : ", pridx);
        state.pridx = pridx;
      }
      if (typeof kdidx !== "undefined") {
        //console.log("^^setGameControl kdidx : ", kdidx);
        state.kdidx = kdidx;
      }
      if (typeof rank !== "undefined") {
        //console.log("^^setGameControl rank : ", rank);
        state.rank = rank;
      }
      if (typeof totalPoint !== "undefined") {
        //console.log("^^setGameControl totalPoint : ", totalPoint);
        state.totalPoint = totalPoint;
      }
      if (typeof progress !== "undefined") {
        //console.log("^^setGameControl progress : ", progress);
        state.progress = progress;
      }
      if (typeof gameCount !== "undefined") {
        //console.log("^^setGameControl gameCount : ", gameCount);
        state.gameCount = gameCount;
      }
      if (typeof gameType !== "undefined") {
        //console.log("^^setGameControl gameType : ", gameType);
        state.gameType = gameType;
      }
      if (typeof gcIdxs !== "undefined") {
        //console.log("^^setGameControl gcIdxs : ", gcIdxs);
        state.gcIdxs = gcIdxs;
      }
      if (typeof optIdxs !== "undefined") {
        //console.log("^^setGameControl optIdxs : ", optIdxs);
        state.optIdxs = optIdxs;
      }
      if (typeof gcPoint !== "undefined") {
        //console.log("^^setGameControl gcPoint : ", gcPoint);
        state.gcPoint = gcPoint;
      }
      if (typeof gcAtStart !== "undefined") {
        //console.log("^^setGameControl gcAtStart : ", gcAtStart);
        state.gcAtStart = gcAtStart;
      }
      if (typeof gcAtEnd !== "undefined") {
        //console.log("^^setGameControl gcAtEnd : ", gcAtEnd);
        state.gcAtEnd = gcAtEnd;
      }
      if (typeof answer !== "undefined") {
        //console.log("^^setGameControl answer : ", answer);
        state.answer = answer;
      }
      if (typeof isCorrect !== "undefined") {
        //console.log("^^setGameControl isCorrect : ", isCorrect);
        state.isCorrect = isCorrect;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postGameSaveAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postGameSaveAsync.fulfilled, (state, action) => {
        //console.log("^^postGameSaveAsync-action.payload : ", action.payload);
        state.status = action.payload.result === true ? "succeeded" : "failed";
        state.error = action.payload.result === true ? "" : "save error";
        state.rank = action.payload.rank;
        // return action.payload.data;  // ^^상태값이 복잡한 객체의 경우 return 으로 대체하지 못한다. (lmmer 원칙에 따라 필요한 필드만 업데이트한다.)
      })
      .addCase(postGameSaveAsync.rejected, (state, action) => {
        //console.log("^^postGameSaveAsync-failed : ", action);
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

// 액션과 리듀서 export
export const { updateGameInfo, resetGame } = gameSlice.actions;
export default gameSlice.reducer;
