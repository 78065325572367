import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import StartComponent from "../../components/game/StartComponent";
import useCustomLogin from "../../hooks/useCustomLogin";
import GameLayout from "../../layout/GameLayout";
import GameModal from "../../components/game/GameModal";
import GameDashboard from "../../components/game/GameDashboard";
import { useDispatch, useSelector } from "react-redux";
import { resetMemberState } from "../../redux/slices/memberSlice";
import { resetGameControl } from "../../redux/slices/gameControlSlice";
import { getGameAll } from "../../api/gameApi";
import { resetGame } from "../../redux/slices/gameSlice";
import { toast } from "react-toastify";

const StartPage = React.memo(() => {
  //console.log("-----StartPage(game)");

  const { isLogin, loginState, moveToLogin } = useCustomLogin();

  const [gameContents, setGameContents] = useState([]);

  const heart = useSelector(
    (state) => state.gameSetSlice.heart // state.gameSetSlice ==> store 의 gameSetSlice 를 가르킨다.
  );

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const memberId = loginState.memberId;

  const { pridx } = useParams();

  useEffect(() => {
    if (!isLogin) {
      console.log("^^Game-StartPage isLogin : ", isLogin);
      dispatch(resetMemberState());
      moveToLogin();
      return;
    }

    if (!pridx) {
      navigate({ pathname: "/main/game-list" }, { replace: true });
      return;
    }
  }, [pridx, memberId, navigate, dispatch, isLogin, moveToLogin]);

  useEffect(() => {
    // 게임 정보를 가져온다. (시대,왕,선택지등 게임 관련된 대부분의 정보)
    getGameAll({ pridx: pridx, memberId: memberId })
      .then((response) => {
        //console.log("^^start-getGameAll-response-data : ", response.data);
        setGameContents(response.data.gameContents);
        dispatch(
          resetGameControl({
            // 전체 초기화 동시에 아래 3개 값 할당.
            pridx: pridx,
            memberId: memberId,
            myGameIdx: response.data.myGameIdx,
            totalGameCount: response.data.gameContents.length,
            prName: response.data.period.name,
            heart: heart,
          })
        );
        dispatch(
          resetGame({
            // 전체 초기화 동시에 값 할당.
            memberId: memberId,
            myGameIdx: response.data.myGameIdx,
            totalGameCount: response.data.gameContents.length,
            heart: heart,
          })
        );
      })
      .catch((err) => {
        console.log("^^start-getGameAll-error : ", err);
        toast.error(err.errorMessage, { theme: "dark" });
      });
  }, [pridx, memberId]);

  const StartComponentMemo = React.memo(
    ({ gameContents }) => {
      return <StartComponent data={{ gameContents }} />;
    },
    (prevProps, nextProps) => prevProps.gameContents === nextProps.gameContents
  );

  const GameDashboardMemo = React.memo(() => {
    return <GameDashboard />;
  });

  // const GameModalMemo = React.memo(({data}) => {
  //   return <GameModal data={data} />;
  // });
  const GameModalMemo = React.memo(() => {
    return <GameModal />;
  });

  return (
    <GameLayout pageClassName="game">
      <div className="inner">
        <GameDashboardMemo />
        <StartComponentMemo gameContents={gameContents} />
      </div>

      <GameModalMemo />
    </GameLayout>
  );
});

export default StartPage;
