import React, { useEffect, useState } from "react";
import useCustomLogin from "../../hooks/useCustomLogin";
import { useSelector } from "react-redux";
import withLoginCheck from "../../hoc/withLoginCheck";
import { getRecord } from "../../api/myApi";
import RecordRowComponent from "./RecordRowComponent";
import { toast } from "react-toastify";

const RecordComponent = () => {
  const [serverData, setServerData] = useState([]);

  const { isLogin, loginState, moveToLogin } = useCustomLogin();

  //console.log("^^record-isLogin", isLogin, "#", loginState);

  if (!isLogin) {
    moveToLogin();
  }

  const memberId = loginState.memberId;

  useEffect(() => {
    getRecord({ memberId: memberId, limit: 100 })
      .then((response) => {
        if (Array.isArray(response)) {
          setServerData(response);
        } else {
          console.error("Expected an array but received:", response);
        }
      })
      .catch((err) => {
        toast.error(err.message, { theme: "dark" });
      });
  }, []);

  return (
    <div className="tab-cont">
      <div className="table">
        <ul className="th">
          <li className="time">
            <em></em>
            <p>Time</p>
          </li>
          <li className="lv">
            <em></em>
            <p>LV</p>
          </li>
          <li className="point">
            <em></em>
            <p>Point</p>
          </li>
          <li className="coin">
            <em></em>
            <p>호두</p>
          </li>
        </ul>
        <div className="list">
          {serverData.length > 0 ? (
            serverData.map((record) => {
              return (
                <RecordRowComponent key={record.myGameIdx} data={record} />
              );
            })
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default withLoginCheck(RecordComponent);
