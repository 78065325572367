import React, { useEffect, useState } from "react";
import useCustomLogin from "../../hooks/useCustomLogin";
import { getGameList } from "../../api/productApi";
import { toast } from "react-toastify";
import GameRowComponent from "./GameRowComponent";

const initState = [];

const GameListComponent = () => {
  const { isLogin, loginState, moveToLogin, refreshLoginState } =
    useCustomLogin();

  const [nation, setNation] = useState("");
  const [pridx, setPrIdx] = useState("");

  const [serverData, setServerData] = useState(initState);

  //console.log("^^loginState : ", loginState.memberId);

  const memberId = loginState.memberId;

  useEffect(() => {
    //console.log("^^GameListComponent-refreshLoginState");
    refreshLoginState();
  }, []);

  useEffect(() => {
    getGameList({ nation, pridx, memberId })
      .then((response) => {
        //console.log("^^getGameList-response.data:", response.data);
        setServerData(response.data);
      })
      .catch((err) => {
        toast.error(err.message, { theme: "dark" });
      });
  }, [nation, pridx, memberId]);

  return (
    <div className="game-list">
      <div className="game-feature">
        {serverData.length > 0 ? (
          serverData.map((product) => {
            //console.log("^^product : ", product);
            return <GameRowComponent key={product.periodIdx} data={product} />;
          })
        ) : (
          <></>
        )}

        {/* <a href="/" className="map">
          <div className="card screen">
            <div className="g-tit">
              <h4>조선킹2</h4>
            </div>
            <div className="g-thum thum-lock">
              <img src="../img/H-lock-img.jpg" alt="" />
            </div>
            <div className="g-info">
              <div className="l-box">
                <p>조선1 ~ 20</p>
              </div>
              <div className="r-box">
                <p>1392 ~ 1910</p>
              </div>
            </div>
          </div>
          <div className="close">
            <div className="lock"></div>
          </div>
        </a> */}
      </div>
    </div>
  );
};

export default GameListComponent;
