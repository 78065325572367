import React, { useMemo, useRef } from "react";
import TextQuizComponent from "./TextQuizComponent";
import ChoiceComponent from "./ChoiceComponent";
import DoorComponent from "./DoorComponent";

// 매개변수-시대키(data : pridx)

const StartComponent = React.memo(({ data }) => {
  //console.log("-----StartComponent : ", data);

  const typeItRef = useRef(null); // TextQuizComponent 컴포넌트의 typeit 인스턴스를 저장할 ref

  const { gameContents } = data;

  // gameContents를 useMemo로 메모이제이션
  const memoizedGameContents = useMemo(() => {
    return gameContents;
  }, [gameContents]);

  const TextQuizComponentMemo = React.memo(({ data }) => {
    // {data} 를 구조 분해 할당
    return <TextQuizComponent data={data} ref={typeItRef} />; // 구조분해 할당된 변수가 data 가 된다. (결국 data 하나만 있다.)
  });

  /**
   * 답안 관련 데이터만 추출해 답안 컴포넌트에 전달
   */
  const memoizedChoiceData = useMemo(() => {
    // 새 배열 데이터 생성
    //console.log("^^choiceComponentData-gameContents : ", gameContents);

    const choiceData = gameContents.map((item) => ({
      gameType: item.gameType,
      gameContentIdx: item.gameContentIdx,
      no: item.no,
      correctAnswer: item.correctAnswer,
      correctList: item.correctList,
      gameOptions: item.gameOptions.map((option) => ({
        gameOptionIdx: option.gameOptionIdx,
      })),
    }));

    return choiceData;
  }, [gameContents]);

  const ChoiceComponentMemo = React.memo(({ data }) => {
    // {data} 를 구조 분해 할당
    return <ChoiceComponent data={data} typeItRef={typeItRef} />; // 구조분해 할당된 변수가 data 가 된다. (결국 data 하나만 있다.)
  });

  const DoorComponentMemo = React.memo(() => {
    return <DoorComponent />;
  });

  return (
    <>
      <div className="content game-content">
        {gameContents.length > 0 ? (
          <>
            <DoorComponentMemo />

            <TextQuizComponentMemo
              data={{ gameContents: memoizedGameContents }}
            />

            <ChoiceComponentMemo data={memoizedChoiceData} />
          </>
        ) : null}
      </div>
    </>
  );
});

export default StartComponent;
