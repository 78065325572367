import React, { useCallback, useEffect, useRef, useState } from "react";
import useCustomGameManage from "../../hooks/useCustomGameManage";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { validateData } from "../../util/validate";
import { setGameControl } from "../../redux/slices/gameControlSlice";
import useCustomNative from "../../hooks/useCustomNative";

const ChoiceComponent = ({ data, typeItRef }) => {
  // console.log("^^ChoiceComponent-data : ", typeItRef, "#", data);

  const [ca, setCa] = useState(null);
  const [cl, setCl] = useState(null);
  const [gcIdx, setGcIdx] = useState(null);
  const [optIdx, setOptIdx] = useState(null);
  const [highlightIndex, setHighlightIndex] = useState(0); // 강조할 버튼 인덱스
  const [showMessage, setShowMessage] = useState(false);

  const highlightIndexRef = useRef(0); // 강조할 버튼 인덱스를 위한 useRef

  const { nativeGame } = useCustomNative();

  const dispatch = useDispatch();

  const totalCount = data.length;

  const gameCount = useSelector((state) => state.gameControlSlice.gameCount);
  const optionCount = useSelector(
    (state) => state.gameControlSlice.optionCount
  );

  const { handleGameCallback } = useCustomGameManage(typeItRef);

  const correctListRefs = useRef([]);

  useEffect(() => {
    initChoice(); // 초기화 (시작시간)

    setCa(data[gameCount].correctAnswer);
    setCl(data[gameCount].correctList.split(","));
    setGcIdx(data[gameCount].gameContentIdx);
    setOptIdx(data[gameCount].gameOptions[optionCount].gameOptionIdx);

    correctListRefs.current.forEach((ref) => {
      if (ref) ref.classList.remove("o", "x");
    });

    // 버튼 강조 효과 추가
    highlightIndexRef.current = 0;
    const highlightInterval = setInterval(() => {
      if (highlightIndexRef.current < 6) {
        //console.log("^^highlight-0000 : ", highlightIndexRef.current);
        // 현재 인덱스 강조
        const currentIndex = highlightIndexRef.current;
        highlightIndexRef.current++; // 인덱스 증가

        // 강조 효과를 적용
        const button = correctListRefs.current[currentIndex];
        if (button) {
          button.classList.add("highlight"); // 강조 클래스 추가
          setTimeout(() => {
            button.classList.remove("highlight"); // 강조 효과 제거
          }, 700); // 700ms 후 강조 효과 제거
        }
      } else {
        //console.log("^^highlight-xxxx");
        clearInterval(highlightInterval); // 모든 버튼 강조 후 인터벌 정리
        setShowMessage(true); // "GO~" 메시지 표시
        setTimeout(() => {
          setShowMessage(false); // 0.5초 후 메시지 사라짐
        }, 500); // 500ms 후 메시지 제거
      }
    }, 800); // 800ms마다 다음 버튼 강조
    return () => clearInterval(highlightInterval); // 컴포넌트 언마운트 시 인터벌
  }, [gameCount]);

  // 1. 정답 체크
  const handleChoice = (e, index, value, ca) => {
    e.preventDefault();

    const isCorrect = validateData(value, ca);

    if (!isCorrect) {
      //console.log("^^App-vibrate()");
      nativeGame({ type: "vibrate", value1: "Y", value2: "" }); // 오답시 진동(RN)
    } else {
      //nativeGame({ type: "sound_typing", value1: "N", value2: "" }); // 사운드 off(RN)
    }

    // console.log(
    //   "^^handleChoice-isCorrect:",
    //   isCorrect.toString(),
    //   "#",
    //   value,
    //   "#",
    //   ca,
    //   "#",
    //   typeItRef
    // );

    correctListRefs.current.forEach((ref) => {
      if (ref) ref.classList.remove("o", "x");
    });

    const _btnClass = isCorrect ? "o" : "x";
    correctListRefs.current.forEach((ref) => ref.classList.remove("o", "x"));
    if (correctListRefs.current[index]) {
      correctListRefs.current[index].classList.add(_btnClass);
    }

    const _gcIdx = data[gameCount].gameContentIdx; // 상태값이 바로 반영이 않되어서 값을 직접 사용
    const _optIdx = data[gameCount].gameOptions[optionCount].gameOptionIdx; // 상태값이 바로 반영이 않되어서 값을 직접 사용

    // 게임이 변경되지 않으면 초기 상태값이 유지가 된다.
    // ChoiceComponent 에서는 optionCount 가 계속 0 이다.
    handleGameCallback({
      value,
      ca,
      gameType: data[gameCount].gameType,
      gcIdx: _gcIdx,
      optIdx: _optIdx,
      optCount: data[gameCount].gameOptions.length,
      totalCount,
      typeItRef,
    });
  };

  const initChoice = () => {
    //console.log("^^initChoice : ", gameCount, "#", optionCount);
    if (optionCount === 0) {
      dispatch(
        setGameControl({
          gcAtStart: Date.now(), // 매 게임 시작 시간
        })
      );
    }
  };

  return (
    <div className="answer-box">
      {cl != null &&
        cl.map((king, index) => (
          <button
            key={index}
            ref={(el) => (correctListRefs.current[index] = el)} //  각 버튼을 배열에 저장
            onClick={(e) => handleChoice(e, index, king, ca)}
            className={highlightIndex === index ? "highlight" : ""} // 강조 클래스 추가
          >
            {king}
          </button>
        ))}
      {showMessage && <div className="go-message">GO~</div>}{" "}
      {/* "GO~" 메시지 표시 */}
      {/*
          {gameControlState.isChoiceResult !== undefined
            ? gameControlState.isChoiceResult.toString().substring(0, 1)
            : "No result"}

        !! 주의 :> 위 코드에서 컴포넌트가 마운트되기 전에 gameControlState가 undefined이거나 null인 경우 발생할 수 있음.
                   gameControlState.isChoiceResult (X)
                   gameControlState.gameControlState?.isChoiceResult (O) :> 옵셔널 체이닝 사용 (gameControlState 이 undefined 라면 오류를 발생시키지 않고 undefined 반환한다. )
                   gameControlState && gameControlState.isChoiceResult (O) :> gameControlState 가 정의 된 겨우 접근 가능
                   useSelector((state) => state.gameControlSlice) || {}; (O) :> 커스텀훅에서 초기값을 빈 객체로 설정
      */}
      {/* 
      <button className="o">영조</button>
      <button className="x">태조</button>
      <button>고종</button> */}
    </div>
  );
};

export default ChoiceComponent;
